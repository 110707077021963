<template>
  <div class="electricStatistics content">
    <div class="list statis1">
      <div class="list_name">能耗统计</div>
      <el-radio-group class="tabList" v-model="nenghaoVal" size="mini" @change="statisticsEnergy()">
        <el-radio-button label="0">日</el-radio-button>
        <el-radio-button label="1">周</el-radio-button>
        <el-radio-button label="2">月</el-radio-button>
        <el-radio-button label="3">年</el-radio-button>
      </el-radio-group>
      <el-date-picker v-model="nenghaoTime" @change="statisticsEnergy()" class="time" size="mini" value-format="yyyy-MM-dd" :type="nenghaoVal=='0'?'date':nenghaoVal=='1'?'week':nenghaoVal=='2'?'month':'year'" placeholder="请选择日期" />
      <!-- <el-button type="primary" size="mini" class="exportBtn"><i class="icon icon-daochu"></i>导出报告</el-button> -->
      <div class="chart" ref="nenghaoRef"></div>
    </div>
    <div class="list statis2">
      <div class="list_name">耗电量环比</div>
      <div class="tableChart">
        <div class="huanbi">
          <div class="item">
            <div class="li">
              <div class="tips">今天(kW·h)</div>
              <div class="num">{{huanbiInfo.today}}</div>
              <div class="detail"></div>
            </div>
            <div class="li">
              <div class="tips">昨天(kW·h)</div>
              <div class="num">{{huanbiInfo.yesterday}}</div>
              <div class="detail"></div>
            </div>
            <div class="li">
              <div class="tips">趋势</div>
              <div class="num">{{huanbiInfo.dayRate}}</div>
              <div class="detail">({{huanbiInfo.dayRateValue}}kW·h)</div>
            </div>
          </div>
          <div class="item">
            <div class="li">
              <div class="tips">本周用电(kW·h)</div>
              <div class="num">{{huanbiInfo.week}}</div>
              <div class="detail"></div>
            </div>
            <div class="li">
              <div class="tips">上周同期(kW·h)</div>
              <div class="num">{{huanbiInfo.lastWeek}}</div>
              <div class="detail"></div>
            </div>
            <div class="li">
              <div class="tips">趋势</div>
              <div class="num">{{huanbiInfo.weekRate}}</div>
              <div class="detail">({{huanbiInfo.weekRateValue}}kW·h)</div>
            </div>
          </div>
          <div class="item">
            <div class="li">
              <div class="tips">本月用电(kW·h)</div>
              <div class="num">{{huanbiInfo.month}}</div>
              <div class="detail"></div>
            </div>
            <div class="li">
              <div class="tips">上月同期(kW·h)</div>
              <div class="num">{{huanbiInfo.lastMonth}}</div>
              <div class="detail"></div>
            </div>
            <div class="li">
              <div class="tips">趋势</div>
              <div class="num">{{huanbiInfo.monthRate}}</div>
              <div class="detail">({{huanbiInfo.monthRateValue}}kW·h)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list">
      <el-radio-group class="tabList" v-model="paimingVal" size="mini" @change="statisticsRank()">
        <el-radio-button label="2">日</el-radio-button>
        <el-radio-button label="0">月</el-radio-button>
        <el-radio-button label="1">年</el-radio-button>
      </el-radio-group>
      <div class="list_name">设备能耗排行</div>
      <el-date-picker v-model="paimingTime" @change="statisticsRank()" class="time" size="mini" value-format="yyyy-MM-dd" :type="paimingVal=='0'?'month':(paimingVal==1?'year':'date')" placeholder="请选择日期" />
      <div class="tableChart">
        <el-table class="tableCont" :data="paimingData" stripe height="100%" style="width: 100%" size="mini">
          <el-table-column type="index" label="排名" width="55"/>
          <el-table-column prop="devName" label="设备"/>
          <el-table-column label="耗电量">
            <template slot-scope="scope">
              <span>{{ scope.row.value }}{{ scope.row.unit }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="list">
      <el-radio-group class="tabList" v-model="changsuoVal" size="mini" @change="statisticsHomeRank()">
        <el-radio-button label="2">日</el-radio-button>
        <el-radio-button label="0">月</el-radio-button>
        <el-radio-button label="1">年</el-radio-button>
      </el-radio-group>
      <div class="list_name">场所能耗排行</div>
      <el-date-picker v-model="changsuoTime" @change="statisticsHomeRank()" class="time" size="mini" value-format="yyyy-MM-dd" :type="changsuoVal=='0'?'month':(changsuoVal==1?'year':'date')" placeholder="请选择日期" />
      <div class="tableChart">
        <el-table class="tableCont" :data="changsuoData" stripe height="100%" style="width: 100%" size="mini">
          <el-table-column type="index" label="排名" width="55"/>
          <el-table-column prop="name" label="场所"/>
          <el-table-column label="耗电量">
            <template slot-scope="scope">
              <span>{{ scope.row.value }}{{ scope.row.unit }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
import { formatTime} from '@/utils/utils'
let vm;
export default {
  data() {
    return {
      nenghaoVal:'0',//能耗日期单选
      nenghaoTime: '',//能耗日期

      huanbiInfo:{
        dayRate: "0.00%",
        dayRateValue: 0,
        lastMonth: 0,
        lastWeek: 0,
        month: 0,
        monthRate: "0.00%",
        monthRateValue: 0,
        today: 0,
        week: 0,
        weekRate: "0.00%",
        weekRateValue: 0,
        yesterday: 0
      },

      paimingVal:'2',//设备排名日期单选
      paimingTime: '',//设备排名日期
      paimingData:[],

      changsuoVal:'2',//场所排名日期单选
      changsuoTime: '',//场所排名日期
      changsuoData:[],

      nenghaoBox:null,
    }
  },
  created(){
    vm = this
  },
  methods:{
    getData(){
      this.statisticsEnergy()
      this.statisticsRatio()
      this.statisticsRank()
      this.statisticsHomeRank()
    },
    //获取能耗统计
    async statisticsEnergy(){
      let dataObj = {
        'areaId':vm.$store.state.csType,
        'homeId':this.$store.state.homeId,
        'date': this.nenghaoTime?this.nenghaoTime:new Date().toISOString().split('T')[0],//如果没有就传今天的日期
        'freq': this.nenghaoVal,
      }
      let res = await apiUrl.statisticsEnergy(qs.stringify(dataObj))
      if(res?.code == 200){
        let nameArr=[],timeArr=[],seriesData=[];
        nameArr = Object.keys(res.data)
        if(nameArr.length>0){
        timeArr = res.data[nameArr[0]].times
        for (let i in res.data) {
          if(res.data.hasOwnProperty(i)) { // 确保 key 是对象自身的属性，而不是原型链上的
            // console.log(i); // 输出键名，例如 "我是名称1" 或 "我是名称2"
            // console.log(res.data[i]); // 输出对应的值，例如 { name: "" }
            let obj = {
              name: i,
              type: 'line',
              smooth: true,
              lineStyle: {
                width: 1
              },
              // 修改的是线的颜色
              // lineStyle: { 
              //   color: { 
              //     type: 'linear',x: 0, y: 1, x2: 0, y2: 0,
              //     colorStops: [{ offset: 0, color: '#3a76f6' },{ offset: .25, color: '#66d4fa' }, { offset: .75, color: '#f8d470'}, { offset: 1, color: '#ec637b'}],
              //     global: false // 缺省为 false
              //   },
              //   width:1
              // },
              areaStyle: i==nameArr[0] ? {
                color: new vm.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(137, 189, 27, 0.3)'
                }, {
                    offset: 0.8,
                    color: 'rgba(137, 189, 27, 0)'
                }], false),
                shadowColor: 'rgba(0, 0, 0, 0.1)',
                shadowBlur: 10
              }:{
                color: new vm.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(0, 136, 212, 0.3)'
                }, {
                    offset: 0.8,
                    color: 'rgba(0, 136, 212, 0)'
                }], false),
                shadowColor: 'rgba(0, 0, 0, 0.1)',
                shadowBlur: 10
              },
              itemStyle: {
                color: i==nameArr[0]?'rgb(137,189,27)':'rgb(0,136,212)'
              },
              data: res.data[i].values
            }
            seriesData.push(obj)
          }
        }
        vm.nenghaoFun(nameArr,timeArr,seriesData)
        }
      }else{
        this.$message.error(res.message)
      }
    },
    //耗电量环比
    async statisticsRatio(){
      let dataObj = {
        'areaId':vm.$store.state.csType,
        'homeId':this.$store.state.homeId,
      }
      let res = await apiUrl.statisticsRatio(qs.stringify(dataObj))
      if(res?.code == 200){
        this.huanbiInfo = {
          dayRate: res.data.dayRate,
          dayRateValue: res.data.dayRateValue,
          lastMonth: res.data.lastMonth,
          lastWeek: res.data.lastWeek,
          month: res.data.month,
          monthRate: res.data.monthRate,
          monthRateValue: res.data.monthRateValue,
          today: res.data.today,
          week: res.data.week,
          weekRate: res.data.weekRate,
          weekRateValue: res.data.weekRateValue,
          yesterday: res.data.yesterday
        }
      }else{
        this.$message.error(res.message)
      }
    },
    //获取设备能耗排名
    async statisticsRank(){
      let dataObj = {
        'areaId':vm.$store.state.csType,
        'homeId':this.$store.state.homeId,
        'freq': this.paimingVal,
        'date': this.paimingTime?this.paimingTime:new Date().toISOString().split('T')[0],//如果没有就传今天的日期
      }
      let res = await apiUrl.statisticsRank(qs.stringify(dataObj))
      if(res?.code == 200){
        this.paimingData = res.data
      }else{
        this.$message.error(res.message)
      }
    },
    //获取场所能耗排名
    async statisticsHomeRank(){
      let dataObj = {
        'areaId':vm.$store.state.csType,
        'homeId':this.$store.state.homeId,
        'freq': this.changsuoVal,
        'date': this.changsuoTime?this.changsuoTime:new Date().toISOString().split('T')[0],//如果没有就传今天的日期
      }
      let res = await apiUrl.statisticsHomeRank(qs.stringify(dataObj))
      if(res?.code == 200){
        this.changsuoData = res.data
      }else{
        this.$message.error(res.message)
      }
    },
    //能耗折线图
    nenghaoFun(nameArr,timeArr,seriesData){
      if(vm.nenghaoBox){
        vm.nenghaoBox?.dispose()
        vm.nenghaoBox = null
      }
      vm.$nextTick(()=>{
        vm.nenghaoBox = vm.$echarts.init(vm.$refs.nenghaoRef); 
        let option = {
          // title: {
          //   text: '能耗统计',
          //   left: 'center',
          //   top:'2%',
          //   textStyle:{
          //     color:'#fff',
          //     fontSize:16,
          //     fontWeight:'normal'
          //   }
          // },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              lineStyle: {
                color: '#57617B'
              }
            }
          },
          legend: {
            icon: 'rect',
            itemWidth: 14,
            itemHeight: 5,
            itemGap: 13,
            data: nameArr,
            right: '4%',
            top: '13%',
            textStyle: {
              fontSize: 12,
              color: '#fff'
            }
          },
          grid: {
            left: '1%',
            right: '3%',
            bottom: '3%',
            top: '25%',
            containLabel: true
          },
          visualMap: {
            type:'continuous',//continuous:连续型，piecewise:分段型
            show: false,
            min: 0, // 映射范围最小值
            max: 30, // 映射范围最大值
            // pieces:[
            //   {min: 0, max: 10},
            //   {min: 10, max: 15},
            //   {min: 15},
            // ],
            inRange: {
              color: ['green', 'yellow', 'red'],
              // symbolSize: [0,10,20]
            },
            seriesIndex: 0
          },
          xAxis: [{
            type: 'category',
            boundaryGap: false,
            axisLabel:{
              color: '#fff',
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,.15)',
              }
            },
            data: timeArr,
          }],
          yAxis: [{
            name: "单位:kW·h",
            type: 'value',
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#fff',
              }
            },
            axisLabel: {
              margin: 10,
              fontSize: 14
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(255,255,255,.15)',
                type:'dashed'
              }
            }
          }],
          series: seriesData
        };
        vm.nenghaoBox.setOption(option)
      })
    },
  }
}
</script>
<style lang='scss'>
.electricStatistics{
  .statis1{
    width: calc(((100% - 10px) / 3) * 2) !important;
  }
  .statis2{
    width: calc((100% - 10px) / 3) !important;
  }
  .huanbi{
    display: flex;
    flex-direction: column;
    height: 100%;

    .item{
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-top: 1px solid #2c3c58;
      flex-grow: 1;
      text-align: center;
      .detail{
        font-size: 14px;
        color: #fff;
      }
      .num{
        font-size: 24px;
        color: #00fbfe;
      }
      .tips{
        font-size: 14px;
        color: #fff;
      }
    }
  }
}
</style>