<template>
  <div class='safetyStatistics mainContainer'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
    <div class="deviceCont">
      <div class="item">
        <img src="../../../assets/images/ui/icon11.png" alt="">
        <div class="itemCont">
          <p>设备总数</p>
          <span class="num"><num-change :val="inform.totalNum"/></span>
        </div>
      </div>
      <div class="item">
        <img src="../../../assets/images/ui/icon12.png" alt="">
        <div class="itemCont">
          <p>在线设备</p>
          <span class="num"><num-change :val="inform.onlineNum"/></span>
        </div>
      </div>
      <div class="item">
        <img src="../../../assets/images/ui/icon13.png" alt="">
        <div class="itemCont">
          <p>离线设备</p>
          <span class="num"><num-change :val="inform.offlineNum"/></span>
        </div>
      </div>
      <div class="item">
        <img src="../../../assets/images/ui/icon14.png" alt="">
        <div class="itemCont">
          <p>报警设备</p>
          <span class="num"><num-change :val="inform.alarmNum"/></span>
        </div>
      </div>
      <div class="item">
        <img src="../../../assets/images/ui/icon15.png" alt="">
        <div class="itemCont">
          <p>故障设备</p>
          <span class="num"><num-change :val="inform.faultNum"/></span>
        </div>
      </div>
      <!-- <div class="item"><span class="num col00ffe"><num-change :val="inform.totalNum"/></span><p>设备总数</p></div>
      <div class="item"><span class="num primary"><num-change :val="inform.onlineNum"/></span><p>在线设备</p></div>
      <div class="item"><span class="num colccc"><num-change :val="inform.offlineNum"/></span><p>离线设备</p></div>
      <div class="item nobd"><span class="num warning"><num-change :val="inform.alarmNum"/></span><p>报警设备</p></div>
      <div class="item nobd"><span class="num error"><num-change :val="inform.faultNum"/></span><p>故障设备</p></div> -->
    </div>
    <el-tabs v-model="tabsActive" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="电气统计" name="1">
        <electric-statistics v-if="tabsActive=='1'" ref="eleCompRef"></electric-statistics>
      </el-tab-pane>
      <el-tab-pane label="线路统计" name="2">
        <line-statistics v-if="tabsActive=='2'" ref="lineCompRef"></line-statistics>
      </el-tab-pane>
      <el-tab-pane label="负载统计" name="3">
        <load-statistics v-if="tabsActive=='3'" ref="loadCompRef"></load-statistics>
      </el-tab-pane>
      <el-tab-pane label="告警统计" name="4">
        <alarm-statistics v-if="tabsActive=='4'" ref="alarmCompRef"></alarm-statistics>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import electricStatistics from './electric-statistics'
import alarmStatistics from './alarm-statistics'
import loadStatistics from './load-statistics'
import lineStatistics from './line-statistics'
import breadCrumb from '@/components/bread-crumb'
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
import { formatTime} from '@/utils/utils'
var vm;
export default {
  name:'safety-statistics',
  data() {
    return {
      tabsActive:'1',//tabs选中

      inform:{
        alarmNum:0,//报警数
        totalNum:0,//设备总数
        faultNum:0,//故障数
        offlineNum:0,//离线数
        onlineNum:0,//在线数
      },
    }
  },
  components:{
    breadCrumb,
    electricStatistics,
    alarmStatistics,
    loadStatistics,
    lineStatistics,
  },
  watch:{
    '$store.state.homeId':function(newVal){
      if(newVal!=='0'){
        vm.getData()
      }
    }
  },
  created(){
    vm = this
    vm.initHeight()
  },
  async mounted(){
    if(vm.$store.state.homeId!=null) vm.getData()
    window.onresize=() => {
      vm.initHeight()
    }
  },

  //方法
  methods:{
    //tabs切换
    handleClick(tab, event) {
      // console.log(tab, event);
      vm.$nextTick(()=>{
        vm.getData()
      })
    },
    //获取设备状态统计
    async statisticsStatus(){
      let dataObj = {
        'areaId':vm.$store.state.csType,
        'homeId':this.$store.state.homeId,
      }
      let res = await apiUrl.statisticsStatus(qs.stringify(dataObj))
      if(res?.code == 200){
        this.inform = {
          alarmNum: res.data.alarm?.total,//报警数
          totalNum: res.data.deviceCount?.total,//设备总数
          faultNum: res.data.fault?.total,//故障数
          offlineNum: res.data.offline?.total,//离线数
          onlineNum: res.data.online?.total,//在线数
        }
      }else{
        this.$message.error(res.message)
      }
    },
    getData(){
      this.statisticsStatus()
      if(vm.tabsActive=='1') vm.$refs.eleCompRef.getData()
      if(vm.tabsActive=='2') vm.$refs.lineCompRef.getData()
      if(vm.tabsActive=='3') vm.$refs.loadCompRef.getData()
      if(vm.tabsActive=='4') vm.$refs.alarmCompRef.getData()
    },
    //设置高度
    initHeight (){
      var minWin = window.innerHeight;
      vm.tableH = minWin-322
    }
  }
}
</script>
<style lang='scss'>
.safetyStatistics{
  .el-tabs{
    margin-top: 10px;
    .el-tabs__content{
      padding: 10px 10px 0;
    }
  }
  .content{
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 296px);
    .list{
      width: calc((100% - 10px) / 2);
      height: calc((100% - 20px) / 2);
      position: relative;
      margin: 0 10px 10px 0;
      background-color: #071a3b;
      border: 1px solid #1d4975;
      box-sizing: border-box;
      &:nth-child(2n){
        margin-right: 0px;
      }
      .tableChart{
        position: absolute;
        left: 0;
        top: 50px;
        right: 0;
        bottom: 0;
        z-index: 0;
        .el-table{
          th{
            background-color: #0b284e;
            color: #00e4ff;
          }
        }
      }
      .chart{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
      }
      .tabList{
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 1;
        height: 30px;
        display: flex;
        align-items: center;
        .el-radio-button{
          
          .el-radio-button__inner{
            background: none;
            color: #838486;
            border: 1px solid #173d66;
            box-shadow: none;
          }
          &.is-active{
            .el-radio-button__inner{
              color: #00def9;
              border-color: #173d66;
            }
          }
        }
      }
      .list_name{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 5px;
        z-index: 1;
        font-size: 18px;
        color: #00e4ff;
        font-weight: bold;
        padding-bottom: 10px;
        &::after{
          content: "";
          position: absolute;
          width: 153px;
          height: 3px;
          background: url('../../../assets/images/ui/title_b.png') no-repeat;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0px;
        }
      }
      .time{
        position: absolute;
        left: 5px;
        top: 5px;
        z-index: 1;
        .el-input__inner{
          background-color: #031e44;
          border-color: #1d4975;
          color: #00c6ff;
          &::placeholder {
            color:  #00c6ff;
          }
        }
      }
      .exportBtn{
        position: absolute;
        left: 235px;
        top: 5px;
        z-index: 1;
        background-color: #031e44;
        border-color: #1d4975;
        color: #00c6ff;
        i{
          font-size:12px;
        }
      }
    }
  }
  .deviceCont{
    display: flex;
    padding: 26px 0;
    background-color: #071a3b;
    box-shadow: 0 5px 27px 5px rgba(7,130,255,.55) inset;
    .item{
      flex-grow: 1;
      text-align: center;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 74px;
        height: 82px;
      }
      .itemCont{
        margin-left: 24px;
        text-align: left;
        p{
          font-size: 22px;
          color: #fff;
          font-weight: bold;
        }
        .num{
          font-size: 36px;
          color: #01f3fc;
        }
      }
      
    }
  }
}
</style>
