<template>
  <div class="alarmStatistics content">
    <div class="list bdr1">
      <el-radio-group class="tabList" v-model="gaojingVal" size="mini" @change="statisticsAlarm()">
        <el-radio-button label="0">日</el-radio-button>
        <el-radio-button label="1">周</el-radio-button>
        <el-radio-button label="2">月</el-radio-button>
      </el-radio-group>
      <div class="list_name">设备告警统计</div>
      <!-- <el-date-picker v-model="nenghaoTime" @change="statisticsAlarm()" class="time" size="mini" value-format="yyyy-MM-dd" type="date" placeholder="请选择日期" /> -->
      <div class="chart" ref="gaojingRef"></div>
    </div>
    <div class="list bdr1">
      <el-radio-group class="tabList" v-model="aisbVal" size="mini" @change="behaviorStatistics()">
        <el-radio-button label="0">日</el-radio-button>
        <el-radio-button label="1">周</el-radio-button>
        <el-radio-button label="2">月</el-radio-button>
      </el-radio-group>
      <div class="list_name">行为告警统计</div>
      <!-- <el-date-picker v-model="nenghaoTime" @change="statisticsAlarm()" class="time" size="mini" value-format="yyyy-MM-dd" type="date" placeholder="请选择日期" /> -->
      <div class="chart" ref="aisbRef"></div>
    </div>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
import { formatTime} from '@/utils/utils'
let vm;
export default {
  data() {
    return {
      gaojingVal:'0',//告警日期单选
      gaojingBox:null,
      
      aisbVal:'0',//行为告警筛选
      aisbBox:null,//行为告警统计
    }
  },
  created(){
    vm = this
  },
  methods:{
    getData(){
      this.statisticsAlarm()
      this.behaviorStatistics()
    },
    //获取告警统计
    async statisticsAlarm(){
      let dataObj = {
        'areaId':vm.$store.state.csType,
        'homeId':this.$store.state.homeId,
        'freq': this.gaojingVal,
      }
      let res = await apiUrl.statisticsAlarm(qs.stringify(dataObj))
      if(res?.code == 200){
        if(res.data?.length>0){
          for(var i in res.data){
            res.data[i].value = parseInt(res.data[i].total)
            res.data[i].name = `${res.data[i].alarmTypeName}-${res.data[i].alarmCategoryName}`
          }
        }
        this.gaojingFun(res.data?.length>0?res.data:[])
      }else{
        this.$message.error(res.message)
      }
    },
    //告警统计饼状图
    gaojingFun(echartData){
      if(vm.gaojingBox){
        vm.gaojingBox?.dispose()
        vm.gaojingBox = null
      }
      vm.$nextTick(()=>{
        vm.gaojingBox = vm.$echarts.init(vm.$refs.gaojingRef); 
        var scale = 1;
        // var echartData = [{
        //     value: 80,
        //     name: '漏电'
        // }, {
        //     value: 9,
        //     name: '跳闸'
        // }, {
        //     value: 170,
        //     name: '短路'
        // }, {
        //     value: 90,
        //     name: '过载'
        // }, {
        //     value: 100,
        //     name: '预警'
        // }]
        var rich = {
            yellow: {
                color: "#ffc72b",
                fontSize: 14 * scale,
                padding: [5, 4],
                align: 'center'
            },
            total: {
                color: "#ffc72b",
                fontSize: 16 * scale,
                align: 'center'
            },
            white: {
                color: "#fff",
                align: 'center',
                fontSize: 14 * scale,
                padding: [5, 0]
            },
            blue: {
                color: '#49dff0',
                fontSize: 14 * scale,
                align: 'center'
            },
            hr: {
                borderColor: '#0b5263',
                width: '100%',
                borderWidth: 1,
                height: 0,
            }
        }
        let option = {
            // backgroundColor: '#141845',
            title: {
                text: '告警总数',
                left: 'center',
                top: '55%',
                padding: [10, 0],
                textStyle: {
                  color: '#fff',
                  fontSize: 14 * scale,
                  align: 'center'
                }
            },
            legend:{
              selectedMode: false,
              formatter: function(name) {
                  var total = 0; //各科正确率总和
                  var averagePercent; //综合正确率
                  if(echartData.length>0){
                    echartData?.forEach(function(value, index, array) {
                        total += value.value;
                    });
                    return '{total|' + total + '}';
                  }else{
                    return '{total|' + 0 + '}'
                  }
              },
              data: echartData.length>0?[echartData[0]?.name]:[],
              left: 'center',
              top: '51%',
              icon: 'none',
              align: 'center',
              textStyle: {
                color: "#fff",
                fontSize: 14 * scale,
                rich: rich
              },
            },
            series: [{
                name: '总告警数量',
                type: 'pie',
                roseType: 'area',
                radius: ['42%', '50%'],
                center:['50%','55%'],
                emphasis:{
                  scale:false,//鼠标经过是否放大
                },
                color: ['#c487ee', '#deb140', '#49dff0', '#034079', '#6f81da', '#00ffb4'],
                label: echartData.length>0 ? {
                  formatter: function(params, ticket, callback) {
                      var total = 0; //事件总数量
                      var percent = 0; //占比
                      echartData?.forEach(function(value, index, array) {
                          total += value.value;
                      });
                      percent = ((params.value / total) * 100).toFixed(1);
                      return '{white|' + params.name + '}\n{hr|}\n{yellow|' + params.value + '}\n{blue|' + percent + '%}';
                  },
                  rich: rich
                }:{
                  show:false
                },
                labelLine: {
                  length: 25 * scale,
                  length2: 0,
                  lineStyle: {
                    color: '#0b5263'
                  }
                },
                data: echartData
            }]
        };
        vm.gaojingBox.setOption(option)
      })
    },
    async behaviorStatistics(){
      let dataObj = {
        'areaId':vm.$store.state.csType,
        'homeId':this.$store.state.homeId,
        'statisticsFlag':vm.aisbVal,
      }
      const res = await apiUrl.behaviorStatistics(dataObj)
      if(res?.code == 200){
        let nameArr = [],valArr=[];
        if(res.data?.length>0){
          for(var i in res.data){
            nameArr.push(res.data[i].alarmBehaviorTypeName)
            valArr.push(res.data[i].totalSize)
          }
        }
        vm.aisbFun(nameArr,valArr)
      }else{
        vm.$message.error(res.message)
      }
    },
    //ai识别饼图
    aisbFun(nameArr,valArr){
      if(vm.aisbBox){
        vm.aisbBox?.dispose()
        vm.aisbBox = null
      }
      vm.$nextTick(()=>{
        vm.aisbBox = vm.$echarts.init(vm.$refs.aisbRef); 
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            top: '20%',
            right: '3%',
            left: '7%',
            bottom: '12%'
          },
          xAxis: [{
            type: 'category',
            data: nameArr,
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,.12)'
              }
            },
            axisLabel: {
              margin: 10,
              color: '#fff',
              fontSize: 14
            },
          }],
          yAxis: [{
            minInterval:1,
            name: '单位:次',
            axisLabel: {
              formatter: '{value}',
              color: '#fff',
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: 'rgba(255,255,255,1)'
              }
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(255,255,255,.12)'
              }
            },
            axisLabel: {
              show: true,
              color: "#fff",
              fontSize: 14
            }
          }],
          series: [{
            type: 'bar',
            data: valArr,
            barWidth: '20px',
            itemStyle: {
              color: new vm.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(0,244,255,1)' // 0% 处的颜色
              }, {
                offset: 1,
                color: 'rgba(0,77,167,1)' // 100% 处的颜色
              }], false),
              borderRadius: [30, 30, 30, 30],
              shadowColor: 'rgba(0,160,221,1)',
              shadowBlur: 4,
            },
            label: {
              show: true,
              lineHeight: 20,
              width:60,
              height: 20,
              backgroundColor: 'rgba(0,160,221,0.1)',
              borderRadius: 200,
              position: ['-8', '-25'],
              distance: 1,
              formatter: [
                '    {d|●}',
                ' {a|{c}}     \n',
                '    {b|}'
              ].join(','),
              rich: {
                d: {
                  color: '#3CDDCF',
                },
                a: {
                  color: '#fff',
                  align: 'center',
                },
                b: {
                  width: 1,
                  height: 10,
                  borderWidth: 1,
                  borderColor: '#234e6c',
                  align: 'left'
                },
              }
            }
          }]
        };
        vm.aisbBox.setOption(option)
      })
    },
  }
}
</script>
<style lang='scss'>
.alarmStatistics{

}
</style>