<template>
  <div class="loadStatistics content">
    <div class="list bdr1">
      <!-- <el-radio-group class="tabList" v-model="gaojingVal" size="mini" @change="statisticOverload()">
        <el-radio-button label="0">日</el-radio-button>
        <el-radio-button label="1">周</el-radio-button>
        <el-radio-button label="2">月</el-radio-button>
      </el-radio-group> -->
      <div class="list_name">今日负载统计</div>
      <el-date-picker v-model="nenghaoTime" @change="statisticOverload()" class="time" size="mini" value-format="yyyy-MM-dd" type="date" placeholder="请选择日期" />
      <div class="chart" ref="fuzaiRef"></div>
    </div>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
import { formatTime} from '@/utils/utils'
let vm;
export default {
  data() {
    return {
      gaojingVal:'0',//告警日期单选
      fuzaiBox:null,
      
      aisbVal:'0',//行为告警筛选
      aisbBox:null,//行为告警统计
      nenghaoTime:'',
    }
  },
  created(){
    vm = this
  },
  methods:{
    getData(){
      this.statisticOverload()
    },
    //获取告警统计
    async statisticOverload(){
      let dataObj = {
        'areaId':vm.$store.state.csType,
        'homeId':this.$store.state.homeId,
        'dayDate': this.nenghaoTime?this.nenghaoTime:new Date().toISOString().split('T')[0],//如果没有就传今天的日期
      }
      let res = await apiUrl.statisticOverload(qs.stringify(dataObj))
      if(res?.code == 200){
        // if(res.data?.length>0){
        //   for(var i in res.data){
        //     res.data[i].value = parseInt(res.data[i].total)
        //     res.data[i].name = `${res.data[i].alarmTypeName}-${res.data[i].alarmCategoryName}`
        //   }
        // }
        console.log(res.data);
        let times = res.data.times||[]
        let values = res.data.values||[]
        this.fuzaiFun(times,values)
      }else{
        this.$message.error(res.message)
      }
    },
    //告警统计饼状图
    fuzaiFun(times,values){
      if(vm.fuzaiBox){
        vm.fuzaiBox?.dispose()
        vm.fuzaiBox = null
      }
      vm.$nextTick(()=>{
        vm.fuzaiBox = vm.$echarts.init(vm.$refs.fuzaiRef); 
        var charts = {
          unit: '单位：W',
          names: ['今天'],
          lineX: times,
          value: values
        }
        let color = ['rgba(23, 255, 243', 'rgba(119,61,190']
        let option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: charts.names,
            textStyle: {
              fontSize: 12,
              color: 'rgb(0,253,255,0.6)'
            },
            right: '4%',
            top: '3%',
          },
          grid: {
            top: '25%',
            left: '3%',
            right: '3%',
            bottom: '5%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: charts.lineX,
            axisLabel: {
              textStyle: {
                color: '#fff'
              },
              formatter: function(params) {
                return params.split(' ')[0] 
              }
            }
          },
          yAxis: {
            name: charts.unit,
            type: 'value',
            axisLabel: {
              formatter: '{value}',
              textStyle: {
                color: '#fff'
              }
            },
            splitLine: {
              lineStyle: {
                color: 'rgb(23,255,243,0.3)'
              }
            },
            axisLine: {
              lineStyle: {
                color: '#fff'
              }
            }
          },
          series: [{
            name: charts.names[0],
            type: 'line',
            color: color[0] + ')',
            smooth: true,
            areaStyle: {
              normal: {
                color: new vm.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: color[0] + ', 0.3)'
                }, {
                  offset: 0.8,
                  color: color[0] + ', 0)'
                }], false),
                shadowColor: 'rgba(0, 0, 0, 0.1)',
                shadowBlur: 10
              }
            },
            symbol: 'circle',
            symbolSize: 5,
            data: charts.value
          }]
        }
        vm.fuzaiBox.setOption(option)
      })
    },
  }
}
</script>
<style lang='scss'>
.loadStatistics{

}
</style>