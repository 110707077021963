<template>
  <div class="lineStatistics content">
    <div class="left_cont">
      <el-input placeholder="输入关键字进行过滤" v-model="filterText" size="small" class="searchBD"></el-input>
      <div class="treeDiv">
        <el-tree
          class="filter-tree"
          :data="treeData"
          :props="defaultProps"
          default-expand-all
          :filter-node-method="filterNode"
          :expand-on-click-node="false"
          ref="treeRef"
          highlight-current
          @node-click="treeClick"
          empty-text="暂无数据">
        </el-tree>
      </div>
    </div>
    <div class="right_cont">
      <div class="searchCont">
        <el-form ref="searchForm" :model="keyWord" label-width="70px" inline @submit.native.prevent size="small">
          <el-form-item label="时间:" prop="time">
            <el-date-picker v-model="keyWord.time" @change="statisticLineHour()" class="time" size="mini" value-format="yyyy-MM-dd" type="date" placeholder="请选择日期" />
          </el-form-item>
          <el-form-item label="类型:" prop="type">
            <el-select v-model="keyWord.type" placeholder="类型" class="w180" multiple collapse-tags clearable @change="statisticLineHour()">
              <el-option v-for="(item,index) in typeData" :key="index" :label="`${item.name}(${item.unit})`" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary" @click="statisticLineHour()" size="small">搜索</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="list2 bdr1">
        <div class="chart" ref="xianluRef"></div>
      </div>
      <template v-if="tableName.length>1">
      <el-table class="tableCont" :data="tableData" stripe :height="tableH" style="width:100%">
        <el-table-column type="index" label="序号"/>
        <el-table-column v-for="column in tableName" :key="column" :prop="column" :label="column"/>
      </el-table>
      </template>
    </div>
    
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
import { formatTime} from '@/utils/utils'
let vm;
export default {
  data() {
    return {
      filterText: '',
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      keyWord: {
        deviceId:'',
        time: '',
        type: []
      },
      xianluBox:null,
      tableName:[],
      typeData:[],//类型列表
      tableData:[],
      tableH: "calc((100% - 53px) / 2)"
    }
  },
  watch: {
    filterText(val) {
      this.$refs.treeRef.filter(val);
    }
  },
  created(){
    vm = this
  },
  methods:{
    getData(){
      this.electricGetLineList()
    },
    //获取路线树形数据
    async electricGetLineList(){
      let dataObj = {
        'areaId':vm.$store.state.csType,
        'homeId':this.$store.state.homeId,
      }
      let res = await apiUrl.electricGetLineList(qs.stringify(dataObj))
      if(res?.code == 200){
        this.treeData = res.data
      }else{
        this.$message.error(res.message)
      }
    },
    //tree单击
    treeClick (data,node){
      if(!data.children){
        this.keyWord.deviceId = data.id
        this.electricTargetList()
      }
    },
    //路线指标列表
    async electricTargetList(){
      let dataObj = {
        'deviceId':this.keyWord.deviceId,
      }
      let res = await apiUrl.electricTargetList(qs.stringify(dataObj))
      if(res?.code == 200){
        this.typeData = res.data||[]
        this.keyWord.type = res.data?.length>0?[res.data[0].id]:[]
        this.statisticLineHour()
      }else{
        this.$message.error(res.message)
      }
    },
    //获取告警统计
    async statisticLineHour(){
      let dataObj = {
        'id':this.keyWord.deviceId,
        'targetIds':this.keyWord.type.join(','),
        'dayDate': this.keyWord.time?this.keyWord.time:new Date().toISOString().split('T')[0],//如果没有就传今天的日期
      }
      let res = await apiUrl.statisticLineHour(qs.stringify(dataObj))
      if(res?.code == 200){
        let names = [],values = [],arr = [];
        for(let x in res.data.times){
          let obj = {
            '数据时间':res.data.times[x],
          }
          arr.push(obj)
        }
        if(res.data.result?.length>0){
          for(let i in res.data.result){
            names.push(`${res.data.result[i].name}(${res.data.result[i].unit})`)
            values.push(res.data.result[i].values)
            for(let y in res.data.result[i].values){
              let name = `${res.data.result[i].name}(${res.data.result[i].unit})`
              arr[y][name] = res.data.result[i].values[y]
            }
          }
        }
        let times = res.data.times||[]
        this.tableData = arr
        this.tableName = [...['数据时间'],...names]
        setTimeout(() => {
          vm.xianluFun(times,names,values)
        }, 100);
      }else{
        this.$message.error(res.message)
      }
    },
    //告警统计饼状图
    xianluFun(times,names,values){
      if(vm.xianluBox){
        vm.xianluBox?.dispose()
        vm.xianluBox = null
      }
      vm.$nextTick(()=>{
        vm.xianluBox = vm.$echarts.init(vm.$refs.xianluRef); 
        var charts = {
          unit: '单位：W',
          names: names,
          lineX: times,
          value: values

        }
        // var colors = ['rgba(23, 255, 243', 'rgba(119,61,190']
        var lineY = []

        for (var i = 0; i < charts.names.length; i++) {
          var x = i
          var data = {
            name: charts.names[i],
            type: 'line',
            // color: colors[x] + ')',
            smooth: true,
            
            symbol: 'circle',
            symbolSize: 10,
            data: charts.value[i]
          }
          lineY.push(data)
        }
        let option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: charts.names,
            textStyle: {
              fontSize: 12,
              color: '#fff'
            },
            left: 'center',
            top: '2%'
          },
          dataZoom: [
            {
              type: 'slider',
              start: 0,
              end: 50
            },
            {
              type: 'inside',
              start: 0,
              end: 50
            }
          ],
          grid: {
            top: '14%',
            left: '3%',
            right: '3%',
            bottom: '6%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: charts.lineX,
            axisLabel: {
              textStyle: {
                color: '#fff'
              },
              formatter: function(params) {
                return params.split(' ')[0] 
              }
            }
          },
          yAxis: {
            // name: charts.unit,
            type: 'value',
            axisLabel: {
              fontSize: 14
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(255,255,255,.15)',
                type:'dashed'
              }
            },
            axisLine: {
              lineStyle: {
                color: '#fff',
              }
            }
          },
          series: lineY
        }
        vm.xianluBox.setOption(option)
      })
    },
    // tree过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
  }
}
</script>
<style lang='scss'>
.lineStatistics{
  .right_cont{
    width:calc(100% - 230px);
    height: 100%;
    .el-table{
      width: 100%;
      // .el-table__body-wrapper{
      //   height: 100%; 
      // }
      height: calc((100% - 53px) / 2);
      overflow: auto;
    }
    .list2{
      height: calc((100% - 53px) / 2);
      position: relative;
      background-color: #071a3b;
      border: 1px solid #1d4975;
      box-sizing: border-box;
      .chart{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
      }
    }
  }
  .left_cont{
    background: #112547;
    box-shadow: 0px -5px 27px 0px rgba(7, 130, 255, 0.55) inset;
    border-right: 1px solid #1d4975;
    margin-right: 10px;
    padding: 10px 10px 0 10px;
    box-sizing: border-box;
    width: 220px;
    .searchBD{
      color: #fff;
      .el-input__inner{
        background: none;
        border-color: #284b96;
        color: #fff;
      }
    }
    .treeDiv{
      height: calc(100% - 32px);
      overflow: auto;
      .el-tree{
        background: none;
        color: #fff;
        .isDisabled{
          color: #999;
        }
      }
      .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
        background:none;
        color:#409EFF;
      }
      .el-tree .el-tree-node.is-focusable>.el-tree-node__content{
        background:none;
      }
      .el-tree-node__content:hover, .el-upload-list__item:hover{
        background: none;
      }
    }
  }
}
</style>